import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import positionName from '../../assets/images/positionName.svg';
import video from '../../assets/images/duo.svg';
import calendar from '../../assets/images/calendar_clock.svg';
import { useDispatch } from 'react-redux';
import { addMinutes, format, parse } from 'date-fns';
import Footer from '../Footer';
import CandidateCalendarNew from './CandidateCalendarNew';
import CandidateThankYou from './CandidateThankYou';
import Header from '../Header';
import { Toast, convertTo12HourFormat, generateUrl } from '../../utils/helpers';
import { postCandidateCalendar, rescheduleCalendarInterview } from '../../redux/action';
import ReschedulePopUp from './ReschedulePopUp';
import { toast } from 'react-toastify';
import Loader from '../Loader';

const CandidateSlots = ({ verifyCalendar, interviewId, type, loading }) => {
    const dispatch = useDispatch();
    const calendarSlotDateAndTime =
        verifyCalendar?.data?.requestId?.panelistId?.panelistCalendarSlot?.calendarSlotDateAndTime;
    const today = new Date();
    const oneWeek = new Date();
    oneWeek.setDate(oneWeek.getDate() + 6);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState({});
    const [isThankYou, setIsThankYou] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [isClearPopup, setIsClearPopup] = useState(false);

    function addMinutesToTime(time24hr, durationMinutes) {
        if (time24hr && durationMinutes) {
            const date = parse(time24hr, 'HH:mm', new Date());
            const endDate = addMinutes(date, durationMinutes);
            return format(endDate, 'HH:mm');
        }
    }
    useEffect(() => {
        if (
            type !== 'reschedule' &&
            verifyCalendar?.data?.requestId?.interviewId?.interviewTime &&
            verifyCalendar?.data?.requestId?.interviewId?.interviewDate
        )
            setIsThankYou(true);
    }, [verifyCalendar]);

    useEffect(() => {
        if (verifyCalendar && verifyCalendar?.data?.requestId?.interviewId?.status === 'cancelled') {
            setIsCancelled(true);
        }
    }, [verifyCalendar]);

    const handleSubmit = () => {
        if (!selectedSlot?.day || !selectedSlot?.start || !selectedSlot?.end) {
            toast.error(<Toast msg={'Please select a time slot.'} />);
        } else {
            dispatch(
                postCandidateCalendar({
                    data: {
                        candidateRequestId: verifyCalendar?.data?._id,
                        candidateCalendarId: verifyCalendar?.data?.requestId?._id,
                        date: selectedSlot?.day,
                        start: selectedSlot?.start,
                        end: selectedSlot?.end,
                    },
                    callback: () => {
                        setIsThankYou(true);
                    },
                })
            );
        }
    };

    const handleReschedule = () => {
        dispatch(
            rescheduleCalendarInterview({
                interviewId: interviewId,
                date: selectedSlot?.day,
                start: selectedSlot?.start,
                timeDuration: verifyCalendar?.data?.requestId?.interviewId?.timeDuration,
                callback: () => {
                    setIsThankYou(true);
                },
            })
        );
    };

    const handlePopUp = () => {
        if (!verifyCalendar?.data?.requestId?.interviewId?.timeDuration || !selectedSlot?.day || !selectedSlot?.start) {
            toast.error(<Toast msg={'Please select a time slot.'} />);
        } else {
            setIsClearPopup(true);
        }
    };

    const isDateWithSlots = (date) => {
        if (date) {
            const formattedDate = format(date, 'yyyy-MM-dd');
            return calendarSlotDateAndTime?.some((slot) => {
                if (slot && slot?.date) {
                    const slotDate = format(new Date(slot?.date), 'yyyy-MM-dd');
                    return slotDate === formattedDate && slot?.interviewSlots?.length > 0;
                } else return false;
            });
        }
    };

    const tileClassName = ({ date, view }) => {
        if (view === 'month' && isDateWithSlots(date)) {
            return 'date-with-slots';
        }
        return null;
    };

    const Content = () => (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="footer-text">Powered by InCruiter India Pvt. Ltd. scheduling platform.</div>
            <div className="footer-text">
                Use is subject to the InCruiter’s{' '}
                <a href={`${generateUrl('website')}/privacy-policy`} class="text-decoration-none">
                    Privacy Policy{' '}
                </a>
                and{' '}
                <a href={`${generateUrl('website')}/terms-and-condition`} className="text-decoration-none">
                    Terms of Service
                </a>
            </div>
        </div>
    );
    const ThankYouContent = () => (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="thank-footer-text">Please connect with recruiter for any further assistance .</div>
        </div>
    );
    return (
        <div className="new-candidate-slots">
            {loading && <Loader />}
            <Header />
            {isCancelled ? (
                <CandidateThankYou type="cancel" />
            ) : isThankYou ? (
                <CandidateThankYou
                    type={type === 'reschedule' ? 'reschedule' : 'candidate'}
                    companyName={verifyCalendar?.data?.requestId?.companyName}
                    date={
                        selectedSlot?.day
                            ? format(selectedSlot?.day, 'dd-MM-yy')
                            : verifyCalendar?.data?.requestId?.interviewId?.interviewDate
                              ? format(verifyCalendar?.data?.requestId?.interviewId?.interviewDate, 'dd-MM-yy')
                              : ''
                    }
                    startTime={
                        selectedSlot?.start
                            ? convertTo12HourFormat(selectedSlot?.start)
                            : verifyCalendar?.data?.requestId?.interviewId?.interviewTime
                              ? convertTo12HourFormat(verifyCalendar?.data?.requestId?.interviewId?.interviewTime)
                              : ''
                    }
                />
            ) : (
                <div className="main-parent">
                    {isClearPopup && (
                        <ReschedulePopUp
                            setIsClearPopup={setIsClearPopup}
                            handleReschedule={handleReschedule}
                            date={verifyCalendar?.data?.requestId?.interviewId?.interviewDate}
                            time={verifyCalendar?.data?.requestId?.interviewId?.interviewTime}
                            duration={verifyCalendar?.data?.requestId?.interviewId?.timeDuration}
                        />
                    )}
                    <div className="main-div">
                        <div className="main-body">
                            <div className="candidate-info w-100">
                                <div className="candidate-name">
                                    {type === 'reschedule'
                                        ? 'Interview:'
                                        : verifyCalendar?.data?.requestId?.name
                                          ? verifyCalendar?.data?.requestId?.name
                                          : ''}{' '}
                                    <br />{' '}
                                    {type === 'reschedule'
                                        ? `${verifyCalendar?.data?.requestId?.name ? verifyCalendar?.data?.requestId?.name : ''}`
                                        : ''}{' '}
                                    {type === 'reschedule' ? <br /> : ''}
                                    {type === 'reschedule'
                                        ? `${`${verifyCalendar?.data?.requestId?.interviewId?.code ? verifyCalendar?.data?.requestId?.interviewId?.code : ''}`}`
                                        : ''}{' '}
                                </div>
                                <div className="interview-schedule">
                                    <div className="title">
                                        {type === 'reschedule' ? 'Reschedule Interview' : 'Schedule your Interview'}
                                    </div>
                                    <div className="schedule-body">
                                        <div className="interview-duration">
                                            {type === 'reschedule' && (
                                                <div className="duration-info">
                                                    <img src={calendar} style={{ height: 21 }} />
                                                    <div>
                                                        {verifyCalendar?.data?.requestId?.interviewId?.interviewTime &&
                                                        verifyCalendar?.data?.requestId?.interviewId?.timeDuration
                                                            ? `${convertTo12HourFormat(
                                                                  verifyCalendar?.data?.requestId?.interviewId
                                                                      ?.interviewTime
                                                              )}`
                                                            : '00:00'}{' '}
                                                        {verifyCalendar?.data?.requestId?.interviewId?.interviewDate
                                                            ? format(
                                                                  verifyCalendar?.data?.requestId?.interviewId
                                                                      ?.interviewDate,
                                                                  'MM-dd-yyyy'
                                                              )
                                                            : ''}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="duration-info">
                                                <img src={positionName} />
                                                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {verifyCalendar?.data?.requestId?.positionName}
                                                </div>
                                            </div>
                                            {type !== 'reschedule' && (
                                                <div className="duration-info">
                                                    <img src={video} />
                                                    <div>Video Interview</div>
                                                </div>
                                            )}
                                        </div>
                                        {type === 'reschedule' && (
                                            <div className="congratulation">
                                                Hi, To reschedule the interview, please select a new date and time that
                                                works for you.
                                            </div>
                                        )}
                                        {!(type === 'reschedule') && (
                                            <div className="congratulation">
                                                Congratulations, <span>{verifyCalendar?.data?.requestId?.name}</span>{' '}
                                                You've successfully moved to the next step and have been selected for
                                                the next round. Our team is excited to meet you at your preferred date
                                                and time.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="calendar-view">
                                <div className="rounded-10 d-center">
                                    <div className="d-center flex-column">
                                        <Calendar
                                            value={selectedDate}
                                            maxDetail="month"
                                            minDetail="month"
                                            minDate={today}
                                            maxDate={oneWeek}
                                            tileClassName={tileClassName}
                                        />
                                        <div className="d-center gap-30 mt-2">
                                            <div className="f-9 fw-400 d-center">
                                                <div className="color-boxes me-2"></div> Currently Selected
                                            </div>
                                            <div className="f-9 fw-400 d-center">
                                                <div className="color-boxes blue me-2"></div> Available Slots
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column w-100">
                                    <div className="calendar-new-head">
                                        <div>Select Interview Slot</div>
                                        {selectedDate && selectedSlot?.start && selectedSlot?.end && (
                                            <div>
                                                {format(selectedDate, 'MMM d, yyyy')} -{' '}
                                                {convertTo12HourFormat(selectedSlot?.start)} to{' '}
                                                {convertTo12HourFormat(selectedSlot?.end)}
                                            </div>
                                        )}
                                    </div>
                                    <CandidateCalendarNew
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                        calendarSlotDateAndTime={calendarSlotDateAndTime}
                                        selectedSlot={selectedSlot}
                                        setSelectedSlot={setSelectedSlot}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="foot-buttons">
                            <button
                                className={`btn save ${loading ? 'disable' : ''} ${type === 'reschedule' ? 'p-1' : ''}`}
                                onClick={type === 'reschedule' ? handlePopUp : loading ? () => {} : handleSubmit}
                            >
                                {type === 'reschedule' ? 'Reschedule Interview' : 'Book Slot'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Footer Content={isThankYou ? ThankYouContent : Content} />
        </div>
    );
};

export default CandidateSlots;
