import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
import alarm from '../assets/images/alarm.svg';
import calendar from '../assets/images/calendar_month.svg';
import { cancelCalendarInterview, verifyCalendarRequest, verifyNewCalendarRequest } from '../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addMinutes, format, parse } from 'date-fns';
import { convertTo12HourFormat } from '../utils/helpers';
import CandidateThankYou from '../component/CandidateCalenderNew/CandidateThankYou';

const CancelCalendarInterview = () => {
    const dispatch = useDispatch();
    let { calendarId } = useParams();
    const verifyCalendar = useSelector((state) => state.CalendarR.verifyNewCalendarRequest);
    const [cancelReason, setCancelReason] = useState('');
    const [error, setError] = useState('');
    const [isThankyou, setIsThankyou] = useState(false);

    function addMinutesToTime(time24hr, durationMinutes) {
        const date = parse(time24hr, 'HH:mm', new Date());
        const endDate = addMinutes(date, durationMinutes);
        return format(endDate, 'HH:mm');
    }

    useEffect(() => {
        if (verifyCalendar && verifyCalendar?.data?.requestId?.interviewId?.status === 'cancelled') {
            setIsThankyou(true);
        }
    }, [verifyCalendar]);

    useEffect(() => {
        if (calendarId && !verifyCalendar) dispatch(verifyNewCalendarRequest({ calendarId }));
    }, [calendarId, verifyCalendar]);

    const handleChange = (e) => {
        setCancelReason(e.target.value);
        if (error) setError('');
    };
    const handleCancel = () => {
        if (!cancelReason) setError('Please provide the reason for interview cancellation');
        else {
            dispatch(
                cancelCalendarInterview({
                    interviewId: verifyCalendar?.data?.requestId?.interviewId?._id,
                    reason: cancelReason,
                    callback: () => {
                        setIsThankyou(true);
                    },
                })
            );
        }
    };

    return (
        <div className="cancel-page">
            <Header />
            {isThankyou ? (
                <CandidateThankYou type="cancel" />
            ) : (
                <>
                    <div className="cancel-head">Cancel Interview</div>
                    <div className="cancel-body">
                        <div className="cancel-body-head">
                            Interview: {verifyCalendar?.data?.requestId?.name} {'< >'}{' '}
                            {verifyCalendar?.data?.requestId?.positionName}
                        </div>
                        <div className="cancel-body-sub-head">
                            <div className="d-center">
                                <img src={calendar} className="me-2" />{' '}
                                {verifyCalendar?.data?.requestId?.interviewId?.interviewTime
                                    ? `${convertTo12HourFormat(
                                          verifyCalendar?.data?.requestId?.interviewId?.interviewTime
                                      )}-${convertTo12HourFormat(
                                          addMinutesToTime(
                                              verifyCalendar?.data?.requestId?.interviewId?.interviewTime,
                                              Number(verifyCalendar?.data?.requestId?.interviewId?.timeDuration)
                                          )
                                      )}`
                                    : '00:00 - 00:00'}{' '}
                                {verifyCalendar?.data?.requestId?.interviewId?.interviewDate
                                    ? format(
                                          verifyCalendar?.data?.requestId?.interviewId?.interviewDate,
                                          'MMMM d, yyyy'
                                      )
                                    : ''}
                            </div>
                            <div className="d-center">
                                <img src={alarm} className="me-2" />{' '}
                                {verifyCalendar?.data?.requestId?.interviewId?.timeDuration} min
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="post-divider-head mb-15">Cancel Interview?</div>
                        <div className="post-divider-head">
                            Reason for canceling<span className="red">*</span>
                        </div>
                        <textarea className="cancel-reason" wrap="soft" onChange={handleChange}></textarea>
                        <button className="cancel-btn" onClick={handleCancel}>
                            Cancel Interview
                        </button>
                        {error && <div className="error-message text-start f-10">{error}</div>}
                    </div>
                </>
            )}
        </div>
    );
};

export default CancelCalendarInterview;
