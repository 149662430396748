import React from 'react';
import logo from '../assets/images/incruiter-main-logo.svg';

const Header = () => {
    return (
        <div className="header">
            <img src={logo} className="image" />
        </div>
    );
};

export default Header;
