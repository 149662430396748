import React, { useEffect } from 'react';
import Header from './Header';
import calendarGif from '../assets/images/calendar_loading.gif';
import { useDispatch, useSelector } from 'react-redux';
import { calendarConnect, verifyCalendarRequest } from '../redux/action';
import { useLocation } from 'react-router-dom';
import { generateUrl, localStorageDecrypt } from '../utils/helpers';
const PanelistPageLoad = () => {
    const dispatch = useDispatch();
    const verifyCalendar = useSelector((state) => state.CalendarR.verifyCalendarRequest);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const scope = queryParams.get('scope');
    const calendarId = localStorageDecrypt('calendarId');
    useEffect(() => {
        dispatch(verifyCalendarRequest({ calendarId }));
    }, [calendarId]);
    useEffect(() => {
        let intId = verifyCalendar?.data?.requestId?.referenceId;
        if (code && intId) {
            dispatch(
                calendarConnect({
                    code: code,
                    mode: scope ? 'Google' : 'Microsoft',
                    user: intId,
                    type: 'panelist',
                    callback: () => {
                        window.location.href = `${generateUrl('calendar')}/sync-interviewer/${calendarId}`;
                    },
                })
            );
        }
    }, [code, verifyCalendar]);

    return (
        <>
            <Header />
            <div className="panelist-sync-load">
                <img src={calendarGif} style={{ height: 100 }} />
            </div>
        </>
    );
};

export default PanelistPageLoad;
