import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { verifyCalendarRequest } from '../redux/action';
import CandidateSlots from '../component/CandidateCalenderNew/CandidateSlots';

const CandidateCalendarSync = ({ type }) => {
    const dispatch = useDispatch();
    let { calendarId, interviewId } = useParams();
    const verifyCalendar = useSelector((state) => state.CalendarR.verifyCalendarRequest);
    const loading = useSelector((state) => state.CalendarR.loading);
    useEffect(() => {
        if (calendarId && !verifyCalendar) dispatch(verifyCalendarRequest({ calendarId }));
    }, [calendarId, verifyCalendar]);

    return <CandidateSlots verifyCalendar={verifyCalendar} interviewId={interviewId} type={type} loading={loading} />;
};

export default CandidateCalendarSync;
